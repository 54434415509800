<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card>
        <h2 class="text-center py-2">
          <span v-if="$store.state.appConfig.layout.isRTL">{{ $t('calculator') }} </span>
          <span>{{ $t($route.params.name) }}</span>
          <span v-if="!$store.state.appConfig.layout.isRTL"> {{ $t('calculator') }}</span>
        </h2>
        <iframe
          id="iframeEmbed"
          :src="`https://calc.fxpcm.net/widget/${$route.params.name}/?${$store.state.appConfig.layout.skin=='semi-dark'?configs.light:configs.dark}`"
          frameborder="0"
          scrolling="no"
          width="100%"
          :onload="onload()"
        />
      </b-card>

    </b-overlay>
  </div>
</template>
<script>

export default {

  data() {
    return {
      show: true,
      configs: {
        light: 'top=YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTs=&bottom=YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==&button=YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOw==&device=RGVza3RvcA==',
        dark: 'top=YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCMzNDM1NDAgMCUsICMyNDI4MzEgMTAwJSk7IGNvbG9yOiB3aGl0ZTs=&bottom=YmFja2dyb3VuZDogIzE1MTgxZDsgYm9yZGVyOiBzb2xpZCAwcHggIzJhMmUzOTsgY29sb3I6ICM5MTk0YTE7&button=YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOw==&device=RGVza3RvcA==',
      },
    }
  },
  watch: {
    '$store.state.appConfig.layout.skin': {
      handler() {
        this.show = true
      },
    },
  },
  methods: {
    onload() {
      window.addEventListener('message', event => {
        if (typeof event.data === 'string' && event.data.includes('bodyheight')) {
          (document.getElementById('iframeEmbed')).height = JSON.parse(event.data).bodyheight.toString()
          this.show = false
        }
      })
    },
  },

}
</script>
